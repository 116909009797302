import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import { MyContext } from "./contexts/AppContext";
import { Booking } from "./models/booking";
import { ListLocalizadorResponse } from "./models/listLocalizador";
import { Registro, RegistroMaletas } from "./models/registroMaletas";
import { CreateReservaModel } from "./models/createReserva";
import { } from "./models/createReserva";

export const registroInit: Registro = {
	bag30: 0,
	bag30Prev: 0,
	bag60: 0,
	bag60Prev: 0,
	bagFree: 0,
	bagFreePrev: 0,
};

export const ERROR_ENTER_BOOKING_NUMBER = "Please enter your booking number";

function App() {
	const [user, setUser] = useState<string>("");

	const [isVisualizeBooking, setIsVisualizeBooking] = useState<boolean>(false);

	const [isSearchLocalizador, setIsSearchLocalizador] = useState<boolean>(true);

	const [localizador, setLocalizador] = useState<string>("");

	const [booking, setBooking] = useState<Booking>({} as Booking);

	const [listLocalizadorResponse, setListLocalizadorResponse] = useState<ListLocalizadorResponse>(
		{} as ListLocalizadorResponse
	);

	const [registroMaletas, setRegistroMaletas] = useState<RegistroMaletas>({
		ida: registroInit,
		vuelta: registroInit,
	});

	const [total, setTotal] = useState<number>(0);

	const [isCreateReserva, setIsCreateReserva] = useState<boolean>(false);

	const [printInboundChecked, setPrintInboundChecked] = useState<boolean>(true);

	const [printOutboundChecked, setPrintOutboundChecked] = useState<boolean>(true);

	const [createReserva, setCreateReserva] = useState<CreateReservaModel>({} as CreateReservaModel);

    const [isSelectTrip, setIsSelectTrip] = useState<boolean>(false);

    const [openPendingPaymentModal, setOpenPendingPaymentModal] = useState<boolean>(false);

	const [hayImportePendiente, setHayImportePendiente] = useState<boolean>(false);

	const [localizadorCorrecto, setLocalizadorCorrecto] = useState<boolean>(false);

	const [validName, setValidName] = useState<boolean>(false);
	
	const [errorMessageLocalizador, setErrorMessageLocalizador] = useState<string>(ERROR_ENTER_BOOKING_NUMBER);

	return (
		<>
			<MyContext.Provider
				value={{
					user,
					setUser,
					isVisualizeBooking,
					setIsVisualizeBooking,
					isSearchLocalizador,
					setIsSearchLocalizador,
					localizador,
					setLocalizador,
					booking,
					setBooking,
					listLocalizadorResponse,
					setListLocalizadorResponse,
					registroMaletas,
					setRegistroMaletas,
					total,
					setTotal,
					isCreateReserva,
					setIsCreateReserva,
					printInboundChecked,
					setPrintInboundChecked,
					printOutboundChecked,
					setPrintOutboundChecked,
					createReserva,
					setCreateReserva,
                    isSelectTrip,
                    setIsSelectTrip,
                    openPendingPaymentModal,
                    setOpenPendingPaymentModal,
					hayImportePendiente,
					setHayImportePendiente,
					localizadorCorrecto,
					setLocalizadorCorrecto,
					validName,
					setValidName,
					errorMessageLocalizador,
					setErrorMessageLocalizador
				}}
			>
				<BrowserRouter>
					<AppRoutes />
				</BrowserRouter>
			</MyContext.Provider>
		</>
	);
}

export default App;
