import axios from "axios";
/** 
 * PRE/PRO
 */
const getBaseUrl = "/maletas/api";

/**
 * LOCALHOST
 */
//  const getBaseUrl = "/maletas/api";
// const getAuthorization = process.env.REACT_APP_ENTORNO==='PRO'?properties.authorizationPro:(process.env.REACT_APP_ENTORNO==='PRE'?properties.authorizationPre:properties.authorizationLocal);
const configAxios = {
   baseURL: getBaseUrl,
//    headers: {
//       'Authorization': getAuthorization
//    }
};

export default axios.create(configAxios);
