import { ThemeProvider } from "@emotion/react";
import { useAppContext } from "../../contexts/AppContext";
import { Grid } from "@mui/material";
import { theme } from "./Theme";
import { RefSearch, Search } from "../../components/search/Search";
import { InfoReserva } from "../../components/infoReserva/InfoReserva";
import ListLocalizadores from "../../components/ListLocalizadores";
import { ListLocalizadorResponse } from "../../models/listLocalizador";
import React, { useEffect } from "react";
import { BookingData } from "../../components/bookingData/BookingData";
import { RefCreateReserva } from "../../components/createReserva/CardCreateReserva";
import { useNavigate } from "react-router-dom";

declare module "@mui/material/styles" {
	interface Theme {
		status: {
			danger: React.CSSProperties["color"];
		};
	}
	interface Palette {
		neutral: Palette["primary"];
	}
	interface PaletteOptions {
		neutral: PaletteOptions["primary"];
	}

	interface PaletteColor {
		darker?: string;
	}
	interface SimplePaletteColorOptions {
		darker?: string;
	}
	interface ThemeOptions {
		status: {
			danger: React.CSSProperties["color"];
		};
	}
}

export default function Home() {

	const {
		isVisualizeBooking,
		setListLocalizadorResponse,
		isSearchLocalizador,
		listLocalizadorResponse,
		setIsSearchLocalizador,
		setLocalizador,
	} = useAppContext();

	const ref = React.useRef<RefSearch>(null);
	const refCreateReserva = React.useRef<RefCreateReserva>(null);
	const navigate = useNavigate();
	const useSelectName = async (locata: string) => {
		setListLocalizadorResponse({} as ListLocalizadorResponse);
		setIsSearchLocalizador(true);
		setLocalizador(locata);
		ref.current?.buscarPorLocalizador(locata);
	};

	useEffect(() => {
		let token = localStorage.getItem("token");
		if(token == null) {
			navigate("/")
		}
		console.log("USER -> " + localStorage.getItem("user"));
	}, [navigate]);

	return (
		<ThemeProvider theme={theme}>
			<Grid
				container
				spacing={1}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					m: "auto",
					width: "100%",
					minHeight: isVisualizeBooking ? "auto" : "100vh",
				}}
			>
				<Grid item xs={12}>
					<Grid
						item
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							padding: "0rem 0.5rem 2rem",
							border: isVisualizeBooking ? "1px solid #008193" : "0px solid #008193",
							borderRadius: "0.5rem",
							marginLeft: "2rem",
							marginRight: "2.5rem",
							maxWidth: 950,
							margin: "auto",
							boxShadow: isVisualizeBooking ? 3 : 0,
						}}
					>
						<Search ref={ref} initFormCrearReserva={refCreateReserva.current?.initFormCrearReserva!} />

						<Grid item xs="auto">
							{isVisualizeBooking && <InfoReserva />}
						</Grid>
					</Grid>

					{!isSearchLocalizador && listLocalizadorResponse.listDataLocalizador?.length > 0 && (
						<Grid item sx={{ m: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
							<Grid item xs={11} md={6} lg={4}>
								<ListLocalizadores selectName={useSelectName} listLocalizadorResponse={listLocalizadorResponse} />
							</Grid>
						</Grid>
					)}
				</Grid>

				<Grid item xs={12}>
					{isVisualizeBooking && <BookingData ref={refCreateReserva} />}
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}
