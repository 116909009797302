import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { auth } from "../services/authService";
import logo from "../CARIBBEAN_BALEARIA_LOGO_2.png";
import "../styles/Login.css";
import { useAppContext } from "../contexts/AppContext";
type Props = {};

const Login = (props: Props) => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);

	const [message, setMessage] = useState<string>("");

	const { setUser } = useAppContext();

	const validationSchema = Yup.object({
		username: Yup.string().required("Username is required"),
		password: Yup.string().required("Password is required"),
	});

	type Login = Yup.InferType<typeof validationSchema>;

	const initialValues: Login = {
		username: "",
		password: "",
	};

	const handleLogin = (login: Login) => {
		setMessage("");
		setLoading(true);
		auth(login.username, login.password).then(
			() => {
                localStorage.setItem("user", login.username);
				setUser(login.username);
				navigate("/home");
			},
			(error) => {
				setLoading(false);
				setMessage(error.response.data);
			}
		);
	};

	return (
		<div className="col-md-12">
			<div className="card card-container">
				<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleLogin}>
					<Form>
						<div className="d-flex flex-column mt-4">
							<img src={logo} alt="logo Baleària" className="w-75 m-auto" />
							<h5 className="text-center m-auto fw-bold mt-2">
								<span className="badge text-bg-secondary">Baggage</span>
							</h5>
						</div>
						<div className="mb-3">
							<label className="label-login" htmlFor="username">
								Username
							</label>
							<Field name="username" type="text" className="form-control" />
							<ErrorMessage name="username" component="div" className="alert-login" />
						</div>

						<div className="mb-3">
							<label className="label-login" htmlFor="password">
								Password
							</label>
							<Field name="password" type="password" className="form-control" />
							<ErrorMessage name="password" component="div" className="alert-login" />
						</div>
						<div className="d-grid gap-2 col-6 mx-auto">
							<button
								type="submit"
								className="btn btn-primary btn-block"
								disabled={loading}
								style={{ backgroundColor: "#008193", borderColor: "#008193" }}
							>
								{loading && <span className="spinner-border spinner-border-sm"></span>}
								<span>Login</span>
							</button>
						</div>

						{message && (
							<div className="form-group">
								<div className="alert-login" role="alert">
									{message}
								</div>
							</div>
						)}
					</Form>
				</Formik>
			</div>
		</div>
	);
};

export default Login;
