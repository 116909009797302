import {
	Avatar,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	Chip,
	FormControlLabel,
	Grid,
	Typography,
} from "@mui/material";
import Luggage from "./Luggage";
import { MasterLuggage } from "../../models/luggageModel";
import vessel from "../../vessel.png";
import { useAppContext } from "../../contexts/AppContext";
import { Routes } from "../../models/masters";

const longEnUSFormatter = new Intl.DateTimeFormat("en-US", {
	year: "numeric",
	month: "long",
	day: "numeric",
	timeZone: "UTC"
});

type Props = {
	isVuelta: boolean;
	setPrintChecked: (value: boolean) => void;
	printChecked: boolean;
};

export function PanelLuggage(props: Props) {
	const { total, setTotal, booking, isCreateReserva, registroMaletas, setRegistroMaletas } = useAppContext();

	const isSalidaBim = () => {
		if (props.isVuelta) {
			return booking.vuelta.origen === "BIM";
		} else {
			return booking.ida.origen === "BIM";
		}
	};

	const setContadorBag30 = (value: number) => {
		if (props.isVuelta) {
			setRegistroMaletas({
				...registroMaletas,
				vuelta: {
					...registroMaletas.vuelta,
					bag30: value,
				},
			});
		} else {
			setRegistroMaletas({
				...registroMaletas,
				ida: {
					...registroMaletas.ida,
					bag30: value,
				},
			});
		}
	};

	const setContadorBag60 = (value: number) => {
		if (props.isVuelta) {
			setRegistroMaletas({
				...registroMaletas,
				vuelta: {
					...registroMaletas.vuelta,
					bag60: value,
				},
			});
		} else {
			setRegistroMaletas({
				...registroMaletas,
				ida: {
					...registroMaletas.ida,
					bag60: value,
				},
			});
		}
	};

	const setContadorBagFree = (value: number) => {
		if (props.isVuelta) {
			setRegistroMaletas({
				...registroMaletas,
				vuelta: {
					...registroMaletas.vuelta,
					bagFree: value,
				},
			});
		} else {
			setRegistroMaletas({
				...registroMaletas,
				ida: {
					...registroMaletas.ida,
					bagFree: value,
				},
			});
		}
	};

	const getDescripcionRuta = (code: string): string => {
        if(code !== null && code !== "") {
            return Routes.filter((route) => route.code === code)[0].descripcion;
        }

        return "";
	};

	const convertFecha = (fecha: string) => {
        if(fecha !== null && fecha !== "") {
            return longEnUSFormatter.format(new Date(fecha));
        }
		
        return "";
	};

	return (
		<Card sx={{ maxWidth: 950, margin: "auto" }}>
			<CardHeader
				sx={{
					backgroundColor: "rgba(1, 135, 134, .25)",
				}}
				avatar={<Avatar alt="" src={vessel} />}
				title={
					<Typography
						sx={{
							color: "rgba(0, 0, 0, 0.87)",
							fontWeight: "bold",
							fontSize: "20px",
							textAlign: "initial",
						}}
					>
						{`${props.isVuelta ? "Inbound" : "Outbound"} - ${
							props.isVuelta
								? convertFecha(booking.vuelta.fechaSalida)
								: convertFecha(booking.ida.fechaSalida)
						}`}
					</Typography>
				}
				subheader={
					<Typography sx={{ color: "rgba(0, 0, 0, 0.87)", textAlign: "initial" }}>
						{`${
							props.isVuelta
								? getDescripcionRuta(booking.vuelta.origen)
								: getDescripcionRuta(booking.ida.origen)
						} - ${
							props.isVuelta
								? getDescripcionRuta(booking.vuelta.destino)
								: getDescripcionRuta(booking.ida.destino)
						}`}
					</Typography>
				}
			></CardHeader>

			<CardContent>
				<Grid item container spacing={1}>
					<Grid item xs={!isCreateReserva && !isSalidaBim()? 8: 10}>
						<Grid item
							sx={{
								borderBottom: 1,
								marginRight: 5,
								marginBottom: 2,
								borderColor: "primary.main",
								display: isCreateReserva ? "none" : "block",
							}}
						>
							<FormControlLabel
								control={
									<Checkbox
										onChange={(e) => props.setPrintChecked(e.target.checked)}
										defaultChecked
									/>
								}
								label={
									<Chip
										label="PRINT"
										variant={props.printChecked ? "filled" : "outlined"}
										color="primary"
									/>
								}
							/>
						</Grid>
						<ul style={{ display: isCreateReserva ? "none" : "block" }}>
							{booking.listaPasajeros?.map((pasajero: { nombre: string }, index: number) => (
								<li key={index}>
									<Typography variant="body1" color="text.primary" sx={{ textAlign: "left" }}>
										{pasajero.nombre}
									</Typography>
								</li>
							))}
						</ul>
					</Grid>

					<Grid item xs={!isCreateReserva && !isSalidaBim()? 4: 2} container spacing={2} sx={{ pt: 2 }}>
						<Grid item xs={!isCreateReserva && !isSalidaBim()? 6: 12}>
							<Luggage
								luggageData={MasterLuggage.bag30}
								totalPrice={total}
								setTotalPrice={setTotal}
								setContador={setContadorBag30}
								contador={props.isVuelta ? registroMaletas.vuelta.bag30 : registroMaletas.ida.bag30}
								contadorPrev={
									props.isVuelta ? registroMaletas.vuelta.bag30Prev : registroMaletas.ida.bag30Prev
								}
							/>

							<Luggage
								luggageData={MasterLuggage.bag60}
								totalPrice={total}
								setTotalPrice={setTotal}
								setContador={setContadorBag60}
								contador={props.isVuelta ? registroMaletas.vuelta.bag60 : registroMaletas.ida.bag60}
								contadorPrev={
									props.isVuelta ? registroMaletas.vuelta.bag60Prev : registroMaletas.ida.bag60Prev
								}
							/>
						</Grid>

						{!isCreateReserva && !isSalidaBim() && (
							<Grid item xs={6}>
								<Luggage
									luggageData={MasterLuggage.bagFree}
									totalPrice={total}
									setTotalPrice={setTotal}
									setContador={setContadorBagFree}
									contador={
										props.isVuelta ? registroMaletas.vuelta.bagFree : registroMaletas.ida.bagFree
									}
									contadorPrev={
										props.isVuelta
											? registroMaletas.vuelta.bagFreePrev
											: registroMaletas.ida.bagFreePrev
									}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
