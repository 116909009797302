import {
	Button,
	Card,
	CardContent,
	CardHeader,
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { BIM, FLL, FPO, Nodo, Nodos } from "../../models/routes";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { HoraSelect } from "./HoraSelect";
import { Fechas, RequestHoraSalida, ResponseFechas, ResponseHoraSalida } from "../../models/horaSalidaModel";
import { GetHoraSalidas } from "../../services/getHoraService";
import Loader from "../loader/Loader";
import { useAppContext } from "../../contexts/AppContext";
import { RouteSelect } from "./RouteSelect";
import { registroInit } from "../../App";
import { GetFechas } from "../../services/getFechasService";
import { useNavigate } from "react-router-dom";

type Props = {};

export type RefCreateReserva = {
	initFormCrearReserva: () => void;
};

export const CardCreateReserva = forwardRef<RefCreateReserva, Props>((props, ref) => {
	const {
		booking,
		setBooking,
		createReserva,
		setCreateReserva,
		setIsSelectTrip,
		isCreateReserva,
		isSelectTrip,
		setRegistroMaletas,
		registroMaletas,
		setTotal,
	} = useAppContext();

	const changeRadio = (value: boolean) => {
		setCreateReserva({ ...createReserva, isVuelta: value });
	};

	const longEnUSFormatter = new Intl.DateTimeFormat("en-US", {
		year: "2-digit",
		month: "2-digit",
		day: "2-digit",
		timeZone: "UTC"
	});

	const ROUND_TRIP = "Round trip";

	const ONLY_ONE_WAY = "Only one way";

	const [origen, setOrigen] = useState<string>("");

	const [destino, setDestino] = useState<string>("");

	const [fechaOrigen, setFechaOrigen] = useState<Dayjs | null>();

	const [fechaDestino, setFechaDestino] = useState<Dayjs | null>();

	const [nodosOrigen, setNodosOrigen] = useState<Nodo[]>(Nodos);

	const [nodosDestino, setNodosDestino] = useState<Nodo[]>(Nodos);

	const [horaSalida, setHoraSalida] = useState<string>("");

	const [horasSalidaDisponible, setHorasSalidaDisponible] = useState<string[]>([] as string[]);

	const [openModal, setOpenModal] = useState<boolean>(false);

	const [fechasAvailable, setFechasAvailable] = useState<ResponseFechas>();

	const [daysAvailable, setDaysAvailable] = useState<string[]>([]);

	const navigate = useNavigate();
	
	type Errores = {
		origen: boolean;
		destino: boolean;
		horaSalida: boolean;
	};

	const [errores, setErrores] = useState<Errores>({} as Errores);

	useEffect(() => {
		setOpenModal(true);
		const result = GetFechas();
		result
			.then((data) => {
				var responseFechas : ResponseFechas = data;
				if(responseFechas.result.result === "OK") {
					setFechasAvailable(responseFechas);
				}
			}).catch(function() {
				localStorage.clear();
				navigate("/");
			}).finally (() => {
				setOpenModal(false);
			});
	}, [navigate]);

	useImperativeHandle(ref, () => ({
		initFormCrearReserva() {
			setOrigen("");
			setDestino("");
			setHoraSalida("");
			setHorasSalidaDisponible([] as string[]);
			setFechaOrigen(dayjs(longEnUSFormatter.format(new Date())));
		},
	}));

	const updateNodosSelect = (isVuelta: boolean, nodo: string) => {
		saveRuta(isVuelta, nodo);

		// Filtro buscador rutas
		switch (nodo) {
			case BIM:
				isVuelta
					? setNodosOrigen(Nodos.filter((item) => item.codigo !== BIM && item.codigo !== FPO))
					: setNodosDestino(Nodos.filter((item) => item.codigo !== BIM && item.codigo !== FPO));
				break;
			case FLL:
				isVuelta
					? setNodosOrigen(Nodos.filter((item) => item.codigo !== FLL))
					: setNodosDestino(Nodos.filter((item) => item.codigo !== FLL));
				break;
			case FPO:
				isVuelta
					? setNodosOrigen(Nodos.filter((item) => item.codigo !== BIM && item.codigo !== FPO))
					: setNodosDestino(Nodos.filter((item) => item.codigo !== BIM && item.codigo !== FPO));
				break;
			default:
				if (isVuelta) {
					setNodosOrigen(Nodos);
					setBooking({
						...booking,
						ida: { ...booking.ida, destino: "" },
						vuelta: { ...booking.vuelta, origen: "" },
					});
				} else {
					setNodosDestino(Nodos);
					setBooking({
						...booking,
						ida: { ...booking.ida, origen: "" },
						vuelta: { ...booking.vuelta, destino: "" },
					});
				}
		}

		// Sacar las horas de salida
		if (isVuelta && origen !== "" && nodo !== "") {
			setHorasSalidaDisponible([]);
			setHoraSalida("");
			getFechas(origen, nodo);
			getHoras(origen, nodo, new Date(fechaOrigen?.format('YYYY-MM-DD')!));
		} else if (!isVuelta && nodo !== "" && destino !== "") {
			setHorasSalidaDisponible([]);
			setHoraSalida("");
			getHoras(nodo, destino, new Date(fechaOrigen?.format('YYYY-MM-DD')!));
			getFechas(nodo, destino);
		}
	};

	const getFechas = (origen: string, destino: string): void => {
		// console.log("ORIGEN -> " + origen)
		// console.log("DESTINO -> " + destino)
		if(fechasAvailable != null) {
			fechasAvailable.listaFechas.map((fecha: Fechas) => {
				if(fecha.ruta === origen + "-" + destino) {
					setDaysAvailable(fecha.fechas);
				}
				return fecha;
			})
		}
	}
	const getHoras = (origen: string, destino: string, fechaSalida: Date): void => {
		var requestHoraSalida: RequestHoraSalida = {
			origen: origen,
			destino: destino,
			fechaSalida: fechaSalida,
		};

		setHorasSalidaDisponible([]);
		setHoraSalida("");
		setOpenModal(true);
		const result = GetHoraSalidas(requestHoraSalida);

		result
			.then((data) => {
				var responseHoraSalida: ResponseHoraSalida = data;
				// console.log(responseHoraSalida);
				if ("OK" === responseHoraSalida.result.result) {
					setHorasSalidaDisponible(responseHoraSalida.horas);
				}
			}).catch(function() {
				localStorage.clear();
				navigate("/");
			}).finally(() => {
				setOpenModal(false);
			});
	};

	const saveRuta = (isVuelta: boolean, nodo: string) => {
		if (nodo !== "") {
			isVuelta
				? setBooking({
						...booking,
						ida: { ...booking.ida, destino: nodo },
						vuelta: { ...booking.vuelta, origen: nodo },
				  })
				: setBooking({
						...booking,
						ida: { ...booking.ida, origen: nodo },
						vuelta: { ...booking.vuelta, destino: nodo },
				  });
		}
	};

	const validate = () => {
		var erroresUpdate: Errores = {
			origen: origen === "",
			destino: destino === "",
			horaSalida: horaSalida === "",
		};

		setErrores(erroresUpdate);

		if (erroresUpdate.origen || erroresUpdate.destino || erroresUpdate.horaSalida) {
			return;
		}

		setTotal(0);
		setRegistroMaletas({
			...registroMaletas,
			ida: registroInit,
			vuelta: registroInit,
		});
		setIsSelectTrip(true);
	};

	const disableRandomDates = (date : Dayjs) =>  {
		return !daysAvailable.includes(date.format('YYYY-MM-DD'));
	}	  

	return (
		<Card sx={{ maxWidth: 950, margin: "auto" }}>
			<CardHeader
				sx={{
					backgroundColor: "rgba(1, 135, 134, .25)",
				}}
				title={
					<Typography
						sx={{
							color: "rgba(0, 0, 0, 0.87)",
							fontWeight: "bold",
							fontSize: "20px",
							textAlign: "initial",
						}}
					>
						Select Trip
					</Typography>
				}
			></CardHeader>

			<CardContent>
				{isCreateReserva && isSelectTrip && (
					<Grid>
						<Button
							sx={{ borderRadius: "25px", fontSize: 15 }}
							variant="contained"
							onClick={() => {
								setTotal(0);
								setRegistroMaletas({
									...registroMaletas,
									ida: registroInit,
									vuelta: registroInit,
								});
								setIsSelectTrip(false);
							}}
						>
							CHANGE TRIP
						</Button>
					</Grid>
				)}

				{isCreateReserva && !isSelectTrip && (
					<Grid item xs={12}>
						<Grid>
							<FormControl>
								<RadioGroup
									row
									defaultValue={ONLY_ONE_WAY}
									id="radioTrip"
									onChange={(e) => changeRadio(e.target.value === ROUND_TRIP)}
								>
									<FormControlLabel value={ONLY_ONE_WAY} control={<Radio />} label={ONLY_ONE_WAY} />
									{/**<FormControlLabel value={ROUND_TRIP} control={<Radio />} label={ROUND_TRIP} />**/}
								</RadioGroup>
							</FormControl>
						</Grid>

						<Grid item container sx={{ pt: 2 }} xs={12} spacing={2}>
							<Grid item xs={12} lg={6}>
								<RouteSelect
									name="Outbound"
									value={origen}
									setValue={setOrigen}
									view={true}
									nodos={nodosOrigen}
									isVuelta={false}
									updateNodosSelect={updateNodosSelect}
									error={errores.origen}
								/>

								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										sx={{ minWidth: 300, display: "flex", mt: 2 }}
										defaultValue={fechaOrigen}
										disablePast
										label="Select date"
										shouldDisableDate={disableRandomDates}
										onChange={(e) => {
											setBooking({
												...booking,
												ida: {
													...booking.ida,
													fechaSalida: e?.format('YYYY-MM-DD')!,
												},
											});
											setFechaOrigen(e);

											if (origen !== "" && destino !== "") {
												getHoras(origen, destino, new Date(e?.format('YYYY-MM-DD')!));
											}
										}}
									/>
								</LocalizationProvider>

								<Grid item sx={{ mt: 2 }}>
									<HoraSelect
										name="outbound-hour"
										value={horaSalida}
										setValue={setHoraSalida}
										horas={horasSalidaDisponible}
										active={horasSalidaDisponible.length > 0 ? false : true}
										vuelta={false}
										error={errores.horaSalida}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12} lg={6}>
								<RouteSelect
									name="Inbound"
									value={destino}
									setValue={setDestino}
									view={true}
									nodos={nodosDestino}
									isVuelta={true}
									updateNodosSelect={updateNodosSelect}
									error={errores.destino}
								/>

								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										sx={{ minWidth: 300, display: createReserva.isVuelta ? "flex" : "none", mt: 2 }}
										label="Select date of departure"
										value={fechaDestino}
										onChange={(e) => {
											setBooking({
												...booking,
												vuelta: {
													...booking.vuelta,
													fechaSalida: e?.format('YYYY-MM-DD')!,
												},
											});
											setFechaDestino(e);
										}}
									/>
								</LocalizationProvider>
							</Grid>
						</Grid>

						<Grid item sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
							<Button
								sx={{ borderRadius: "25px", fontSize: 14 }}
								variant="contained"
								disabled={false}
								onClick={validate}
							>
								CONTINUE
							</Button>
						</Grid>
					</Grid>
				)}
			</CardContent>

			<Loader open={openModal} />
		</Card>
	);
});
