export type LuggageData = {
	name: string;
	price: number;
};

export type LuggageModel = {
    bag30: LuggageData;
    bag60: LuggageData;
    bagFree: LuggageData;
}

export const MasterLuggage: LuggageModel = {
	bag30: {
		name: "bag30",
		price: 30,
	},
	bag60: {
		name: "bag60",
		price: 60,
	},
    bagFree: {
		name: "bag60",
		price: 0,
	},
};
