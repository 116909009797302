import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
	components: {
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					"&.Mui-error": {
						color: "#e53e3e",
					},
					color: "#008193",
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					color: "#008193",
				},
			},
		},
	},
	status: {
		danger: "#e53e3e",
	},
	palette: {
		primary: {
			main: "#008193",
			darker: "#008193",
		},
		neutral: {
			main: "#008193",
			contrastText: "#fff",
		},
	},
});