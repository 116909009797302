import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

type Props = {
    openModal: boolean,
    handleClose: () => void
}

export function ModalPaymentError(props: Props) {
	return (
		<Dialog
			open={props.openModal}
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{"Not Allowed Operation"}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Please check if you have selected at least one baggage in order to proceed with the payment.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={props.handleClose}
					autoFocus
					sx={{ borderRadius: "25px", fontSize: 16, width: 180 }}
					variant="contained"
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}
