import React from 'react'
import { Booking } from '../models/booking';
import { Navigate } from "react-router-dom"

type Props = {
    booking : Booking | null;
    children: any;

}

const BookingProtectedRoutes = ({booking, children}: Props) => {
    // console.log("BOOKING -> " + booking);
    if(booking === null || booking.localizador === "") return <Navigate to="/" />
    return children
}

export default BookingProtectedRoutes