import apiAxios from "./apiAxios";

export const GetFechas = async () => {

	const result = await apiAxios.get(`/fechas`, {
		headers: {
			Authorization: localStorage.getItem("token"),
		},
	});

	return result.data;
};