import React, { useContext } from "react";
import { Booking } from "../models/booking";
import { ListLocalizadorResponse } from "../models/listLocalizador";
import { RegistroMaletas } from "../models/registroMaletas";
import { CreateReservaModel } from "../models/createReserva";

export type Content = {
	user: string;
	setUser: (value: string) => void;
	isVisualizeBooking: boolean;
	setIsVisualizeBooking: (value: boolean) => void;
	isSearchLocalizador: boolean;
	setIsSearchLocalizador: (value: boolean) => void;
	localizador: string;
	setLocalizador: (value: string) => void;
	booking: Booking;
	setBooking: (value: Booking) => void;
	listLocalizadorResponse: ListLocalizadorResponse;
	setListLocalizadorResponse: (value: ListLocalizadorResponse) => void;
	registroMaletas: RegistroMaletas;
	setRegistroMaletas: (value: RegistroMaletas) => void;
	total: number;
	setTotal: (value: number) => void;
	isCreateReserva: boolean;
	setIsCreateReserva: (value: boolean) => void;
	printInboundChecked: boolean;
	setPrintInboundChecked: (value: boolean) => void;
	printOutboundChecked: boolean;
	setPrintOutboundChecked: (value: boolean) => void;
	createReserva: CreateReservaModel;
	setCreateReserva: (value: CreateReservaModel) => void;
	isSelectTrip: boolean;
	setIsSelectTrip: (value: boolean) => void;
    openPendingPaymentModal: boolean;
    setOpenPendingPaymentModal: (value: boolean) => void;
	hayImportePendiente : boolean;
	setHayImportePendiente: (value: boolean) => void;
	localizadorCorrecto: boolean;
	setLocalizadorCorrecto: (value: boolean) => void;
	validName: boolean;
	setValidName: (value: boolean) => void;
	errorMessageLocalizador: string;
	setErrorMessageLocalizador: (value: string) => void;

};

export const MyContext = React.createContext<Content>({
	user: "",
	setUser: () => {},
	isVisualizeBooking: false,
	setIsVisualizeBooking: () => {},
	isSearchLocalizador: false,
	setIsSearchLocalizador: () => {},
	localizador: "",
	setLocalizador: () => {},
	booking: {} as Booking,
	setBooking: () => {},
	listLocalizadorResponse: {} as ListLocalizadorResponse,
	setListLocalizadorResponse: () => {},
	registroMaletas: {} as RegistroMaletas,
	setRegistroMaletas: () => {},
	total: 0,
	setTotal: () => {},
	isCreateReserva: false,
	setIsCreateReserva: () => {},
	printInboundChecked: false,
	setPrintInboundChecked: () => {},
	printOutboundChecked: false,
	setPrintOutboundChecked: () => {},
	createReserva: {} as CreateReservaModel,
	setCreateReserva: () => {},
	isSelectTrip: false,
	setIsSelectTrip: () => {},
    openPendingPaymentModal: false,
    setOpenPendingPaymentModal: () => {},
	hayImportePendiente : false,
	setHayImportePendiente: () => {},
	localizadorCorrecto: false,
	setLocalizadorCorrecto: () => {},
	validName: false,
	setValidName: () => {},
	errorMessageLocalizador: "",
	setErrorMessageLocalizador: () => {}
})

export const useAppContext = () => useContext(MyContext);
