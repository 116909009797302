export interface Nodo {
	codigo: string;
	descripcion: string;
}

export const BIM = "BIM";

export const BIMDescripcion = "Bimini";

export const FLL = "FLL";

export const FLLDescripcion = "Ft Lauderdale";

export const FPO = "FPO";

export const FPODescripcion = "G. Bahama";

export const Nodos: Nodo[] = [
	{
		codigo: BIM,
		descripcion: BIMDescripcion,
	},
	{
		codigo: FLL,
		descripcion: FLLDescripcion,
	},
	{
		codigo: FPO,
		descripcion: FPODescripcion,
	},
];
