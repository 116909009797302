import { Grid } from "@mui/material";
import { useAppContext } from "../../contexts/AppContext";
import { PanelLuggage } from "../luggage/PanelLuggage";
import { CardCreateReserva, RefCreateReserva } from "../createReserva/CardCreateReserva";
import React, { forwardRef, useImperativeHandle } from "react";

type Props = {};

export const BookingData= forwardRef<RefCreateReserva, Props>((props, ref) => {
	const {
		booking,
		isCreateReserva,
		setPrintInboundChecked,
		setPrintOutboundChecked,
		printInboundChecked,
		printOutboundChecked,
		isSelectTrip,
        createReserva
	} = useAppContext();

    const refCreateReserva = React.useRef<RefCreateReserva>(null);

    useImperativeHandle(ref, () => ({
		initFormCrearReserva() {
            refCreateReserva.current?.initFormCrearReserva();
        }
    }));

	return (
		<Grid
			container
			spacing={1}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				mt: "10px",
				mb: 5,
			}}
		>
			{isCreateReserva  && (
				<Grid item xs={12}>
					<CardCreateReserva ref={refCreateReserva} />
				</Grid>
			)}

			{(!isCreateReserva || (isCreateReserva && isSelectTrip)) && (
				<>
					<Grid item xs={12}>
						<PanelLuggage
							isVuelta={false}
							setPrintChecked={setPrintOutboundChecked}
							printChecked={printOutboundChecked}
						/>
					</Grid>

					{((booking.vuelta && booking.vuelta !== null && !isCreateReserva) ||
						(isCreateReserva && booking.vuelta !== null && createReserva.isVuelta)) && (
						<Grid item xs={12}>
							<PanelLuggage
								isVuelta={true}
								setPrintChecked={setPrintInboundChecked}
								printChecked={printInboundChecked}
							/>
						</Grid>
					)}
				</>
			)}
		</Grid>
	);
});
