import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { useAppContext } from "../../contexts/AppContext";
import { validateToken } from "../../services/authService";
import { forwardRef, useImperativeHandle, useState } from "react";
import { SearchLocalizador } from "./SearchLocalizador";
import { SearchNamePassenger } from "./SearchNamePassenger";
import { Registro, RegistroMaletas } from "../../models/registroMaletas";
import { searchLocalizadorService } from "../../services/searchService";
import { Booking, ListaServiciosExtra, initBooking } from "../../models/booking";
import { registroInit } from "../../App";
import { ModalPendingPayment } from "../modals/ModalPendingPayment";
import { initCreateReserva } from "../../models/createReserva";
import {ListLocalizadorResponse} from "../../models/listLocalizador";
import { useNavigate } from "react-router-dom";
type Props = {
	initFormCrearReserva: () => void;
};

export type RefSearch = {
	buscarPorLocalizador: (locata: string) => void;
};

export const Search = forwardRef<RefSearch, Props>((props, ref) => {
	const {
		isSearchLocalizador,
		setIsSearchLocalizador,
		isVisualizeBooking,
		setTotal,
		setRegistroMaletas,
		setBooking,
		setIsVisualizeBooking,
		setIsCreateReserva,
		setLocalizador,
		setCreateReserva,
		isCreateReserva,
		setIsSelectTrip,
		registroMaletas,
		setOpenPendingPaymentModal,
		setHayImportePendiente,
		setValidName,
		setLocalizadorCorrecto,
		setListLocalizadorResponse
	} = useAppContext();

	useImperativeHandle(ref, () => ({
		buscarPorLocalizador(locata: string) {
			buscarPorLocalizador(locata);
		},
	}));

	const {errorMessageLocalizador, setErrorMessageLocalizador} =  useAppContext();

	const [loading, setLoading] = useState<boolean>(false);

	const navigate = useNavigate();
	
	const buscarPorLocalizador = async (locata: string) => {
		setIsVisualizeBooking(false);
		validateToken();
		setHayImportePendiente(false);
		setIsCreateReserva(false);
		setIsSelectTrip(false);
		setLoading(true);
		setTotal(0);
		setBooking(initBooking);

		const result = searchLocalizadorService(locata);

		result
			.then((data) => {
				var registroMaletasNew: RegistroMaletas = {
					ida: registroInit,
					vuelta: registroInit,
				};

				var booking: Booking = data;

				if (booking.localizador === locata && booking.error === null) {
					registroMaletasNew.ida = generateRegistro(booking.ida.listaServiciosExtra);
					if (booking.vuelta !== null) {
						registroMaletasNew.vuelta = generateRegistro(booking.vuelta.listaServiciosExtra);
					}

					setIsVisualizeBooking(true);
				} else {
					if (booking.error !== null) {
						setErrorMessageLocalizador(booking.error);
					} else {
						setErrorMessageLocalizador("Booking number NOT FOUND");
					}

					setIsVisualizeBooking(false);
				}

				setBooking(booking);
				setRegistroMaletas(registroMaletasNew);

				if (booking.importePendiente !== null && booking.importePendiente !== 0) {
					setTotal(booking.importePendiente);
					setOpenPendingPaymentModal(true);
					setHayImportePendiente(true);
				}
			}).catch(function() {
				localStorage.clear();
				navigate("/");
			}).finally(() => {
				setIsCreateReserva(false);
				setLoading(false);
				// setLocalizadorCorrecto(false);
			});
	};

	const generateRegistro = (listaServiciosExtra: ListaServiciosExtra): Registro => {
		return {
			bag30: listaServiciosExtra.maletas30,
			bag30Prev: listaServiciosExtra.maletas30,
			bag60: listaServiciosExtra.maletas60,
			bag60Prev: listaServiciosExtra.maletas60,
			bagFree: listaServiciosExtra.maletasFree,
			bagFreePrev: listaServiciosExtra.maletasFree,
		};
	};

	return (
		<Grid
			sx={{
				border: isVisualizeBooking ? "0px solid #008193" : "1px solid #008193",
				borderRadius: "0.5rem",
				padding: "2rem 2rem",
				boxShadow: isVisualizeBooking ? 0 : 3,
			}}
		>
			<Grid sx={{ paddingBottom: "1rem" }}>
				<Button
					sx={{
						borderRadius: "25px",
						fontSize: 12,
					}}
					variant="contained"
					onClick={() => {
						setIsVisualizeBooking(true);
						setIsCreateReserva(true);
						//initCrearReserva();
						setLocalizador("");
						setBooking(initBooking);
						setCreateReserva(initCreateReserva);
						setTotal(0);
						setRegistroMaletas({
							...registroMaletas,
							ida: registroInit,
							vuelta: registroInit,
						});
						setIsSelectTrip(false);
						setValidName(false);
						setLocalizadorCorrecto(false);
						setListLocalizadorResponse({} as ListLocalizadorResponse);
						setHayImportePendiente(false);

						if (props.initFormCrearReserva !== undefined) {
							props.initFormCrearReserva();
						}
					}}
				>
					{!isCreateReserva ? "Create Booking" : "Reset Create Booking"}
				</Button>
			</Grid>

			<FormControl>
				<RadioGroup
					row
					defaultValue="localizador"
					id="radioSearch"
					onChange={(e) => setIsSearchLocalizador(e.target.value === "localizador")}
				>
					<FormControlLabel
						checked={isSearchLocalizador}
						value="localizador"
						control={<Radio />}
						label="Booking Number"
						name="radioSearch"
					/>
					<FormControlLabel checked={!isSearchLocalizador} value="name" control={<Radio />} label="Passenger's Name" />
				</RadioGroup>
			</FormControl>

			{isSearchLocalizador && (
				<SearchLocalizador
					buscarPorLocalizador={buscarPorLocalizador}
					errorMessage={errorMessageLocalizador}
					setErrorMessage={setErrorMessageLocalizador}
					loading={loading}
				/>
			)}

			{!isSearchLocalizador && <SearchNamePassenger />}

			<ModalPendingPayment />
		</Grid>
	);
});
