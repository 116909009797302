import { Avatar, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import luggage from "../../luggage_balearia.png";
import luggageFree from "../../luggage_balearia_free.png";
import { LuggageData, MasterLuggage } from "../../models/luggageModel";

type Props = {
	luggageData: LuggageData;
	setTotalPrice: (value: number) => void;
	totalPrice: number;
	contador: number;
	setContador: (value: number) => void;
	contadorPrev: number;
};

const Luggage: React.FC<Props> = ({ luggageData, setTotalPrice, totalPrice, contador, setContador, contadorPrev }) => {
	const ImageLuggage = (): JSX.Element => {
		if (MasterLuggage.bag60 === luggageData) {
			return <Avatar alt="" src={luggage} sx={{ width: "110px", height: "110px", m: "auto", p: 1 }} />;
		} else if (MasterLuggage.bagFree === luggageData) {
			return <Avatar alt="" src={luggageFree} sx={{ width: "70px", height: "70px", m: "auto", p: 1 }} />;
		}

		return <Avatar alt="" src={luggage} sx={{ width: "70px", height: "70px", m: "auto", p: 1 }} />;
	};

	const add = () => {
		setContador(contador + 1);
		setTotalPrice(totalPrice + luggageData.price);
	};

	const remove = () => {
		if (contador !== 0 && contador > contadorPrev) {
			setContador(contador - 1);
			setTotalPrice(totalPrice - luggageData.price);
		}
	};

	return (
		<Grid>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					boxShadow:
						"rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;",
					borderRadius: 2,
				}}
			>
				<ImageLuggage />
			</Box>
			<Grid
				container
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Grid item xs={8} sx={{ mt: 1, mb: 1 }}>
					<AddCircleRoundedIcon
						sx={{ color: "#008193", fontSize: "2.5em", cursor: "pointer" }}
						onClick={() => add()}
					></AddCircleRoundedIcon>
					<RemoveCircleRoundedIcon
						sx={{
							color: "#008193",
							fontSize: "2.5em",
							opacity: contador !== 0 && contador > contadorPrev ? 1 : 0.5,
							cursor: contador !== 0 && contador > contadorPrev ? "pointer" : "",
						}}
						onClick={() => remove()}
					></RemoveCircleRoundedIcon>
				</Grid>
				<Grid item xs={4}>
					<Typography style={{ fontSize: "20px", fontWeight: "bold" }}>x{contador}</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Luggage;
