import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useAppContext } from "../../contexts/AppContext";



export function ModalPendingPayment() {
	const { booking, openPendingPaymentModal, setOpenPendingPaymentModal } = useAppContext();

    const close = () => {
        setOpenPendingPaymentModal(false);
    }

	return (
		<Dialog
			open={openPendingPaymentModal}
			onClose={close}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{"PENDING PAYMENT"}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<span style={{ color: "red", fontWeight: "bold" }}>
						There is a pending payment.
						<br />
						Import: $ {booking.importePendiente}
						<br/>
						<br/>
						<span style={{fontSize: 18}}>**You have to create a new booking in order to add luggage**</span>
					</span>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ m: "auto" }}>
				<Button
					onClick={close}
					autoFocus
					sx={{ borderRadius: "25px", fontSize: 16, width: 180 }}
					variant="contained"
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}
