import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

type Props = {
	openModal: boolean;
	handleClose: () => void;
	openPrintView: () => void;
    times: string;
};

export function ModalNumberPrintLabel(props: Props) {	
	return (
		<Dialog
			open={props.openModal}
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title"><span style={{color: "red"}}>WARNING!!</span></DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<p style={{color: "red", fontWeight: "bold"}}>THIS TAGS HAVE BEEN PRINTED <span style={{fontWeight: "bold"}}>{props.times}</span> TIMES!</p>
                    <p style={{color: "red", fontWeight: "bold"}}>BEFORE PROCEEDING, YOU MUST MAKE SURE THAT YOU ARE NOT GIVING AWAY TAGS BY MISTAKE!</p>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={props.handleClose}
					sx={{ borderRadius: "25px", fontSize: 16, width: 180 }}
					variant="contained"
				>
					Close
				</Button>
				<Button
					onClick={props.openPrintView}
					autoFocus
					sx={{backgroundColor: "#f0a000", borderRadius: "25px", fontSize: 16, width: 180 }}
					variant="contained"
				>
					Continue
				</Button>
			</DialogActions>
		</Dialog>
	);
}
