import { Routes, Route } from "react-router-dom";
import Login from "../views/Login";
import Home from "../views/home/Home";
import BookingProtectedRoutes from "./BookingProtectedRoutes";
import { useAppContext } from "../contexts/AppContext";
import PrintBaggageTicket from "../views/PrintBaggageTicket";

type Props = {};

const AppRoutes = (props: Props) => {
	const { booking } = useAppContext();

	return (
		<Routes>
			<Route index element={<Login />} />
			<Route path="home" element={<Home />} />
			<Route
				path="printBaggageTags"
				element={
					<BookingProtectedRoutes booking={booking}>
						{booking !== null ? <PrintBaggageTicket /> : <h1>Not allowed to make such operation</h1>}
					</BookingProtectedRoutes>
				}
			/>
		</Routes>
	);
};

export default AppRoutes;
