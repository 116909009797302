import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

import { useAppContext } from "../../contexts/AppContext";

type Props = {
	name: string;
	value: string;
	setValue: (value: string) => void;
	horas: string[];
	active: boolean;
	vuelta: boolean;
	error: boolean;
};

export function HoraSelect(props: Props) {
	const { createReserva, setCreateReserva } = useAppContext();

	return (
		<FormControl required sx={{ minWidth: 300, display: "flex" }} disabled={props.active} error={props.error}>
			<InputLabel id={props.name}>{props.name}</InputLabel>
			<Select
				labelId={props.name}
				id={`select-hour-${props.name}`}
				value={props.value}
				label={`${props.name} *`}
				onChange={(e) => {
					props.setValue(e.target.value);
					if (props.vuelta) {
					} else {
						setCreateReserva({
							...createReserva,
							horaSalida: e.target.value !== "" ? parseInt(e.target.value.replace(":", "")) : 0,
						});
					}
				}}
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{props.horas.map((hora: string, index: number) => {
					return (
						<MenuItem key={index} value={hora}>
							{hora}
						</MenuItem>
					);
				})}
			</Select>
			<FormHelperText>Required</FormHelperText>
		</FormControl>
	);
}
