import { Result } from "./result";

export type CreateReservaModel = {
	origen: string;
	destino: string;
	isVuelta: boolean;
	fechaIda: string;
	fechaVuelta: string;
	horaSalida: number;
	maletasIda: Maletas;
	maletasVuelta: Maletas;
};

export type Maletas = {
	maletas30: number;
	maletas60: number;
	maletasFree: number;
};

export const initMaletas: Maletas = {
	maletas30: 0,
	maletas60: 0,
	maletasFree: 0,
};

export const initCreateReserva = {
	origen: "",
	destino: "",
	isVuelta: false,
	fechaIda: "",
	fechaVuelta: "",
	horaSalida: 0,
	maletasIda: initMaletas,
	maletasVuelta: initMaletas,
};

export type ResponseCreateReserva = {
	localizador: string;
	result: Result;
};
