import { Box, Button, Grid, Typography } from "@mui/material";
import { useAppContext } from "../../contexts/AppContext";
import { Registro } from "../../models/registroMaletas";
import { useState } from "react";
import { ModalPayment } from "../modals/ModalPayment";
import { ModalPaymentError } from "../modals/ModalPaymentError";

export function BuyReserva() {
	const { total, isCreateReserva, registroMaletas, hayImportePendiente } = useAppContext();

	const [openModalPayment, setOpenModalPayment] = useState<boolean>(false);

	const [openModalPaymentError, setOpenModalPaymentError] = useState<boolean>(false);

	const validateAddFree = (registro: Registro) => {
		if (registro.bagFree > registro.bagFreePrev) {
			return true;
		}

		return false;
	};

	const handleBuy = () => {
		if (
			total > 0 ||
			(total === 0 && (validateAddFree(registroMaletas.ida) || validateAddFree(registroMaletas.vuelta)) && !hayImportePendiente)
		) {
			setOpenModalPayment(true);
			setOpenModalPaymentError(false);
		} else {
			setOpenModalPaymentError(true);
		}
	};

	return (
		<Grid item xs="auto">
			<Box sx={{ flexDirection: "row", ml: 5, textAlign: "center" }}>
				<Box
					sx={{
						bgcolor: "background.paper",
						boxShadow: 3,
						borderRadius: 2,
						p: 2,
						ml: 2,
						minWidth: 200,
					}}
				>
					<Typography variant="h6" color="text.primary" sx={{ fontWeight: "bold" }}>
						Total:
					</Typography>
					<Typography sx={{ color: "text.primary", fontSize: 34, fontWeight: "bold" }}>$ {total}</Typography>
				</Box>
				<Button
					sx={{ mt: 1, borderRadius: "25px", fontSize: 16, width: 120 }}
					variant="contained"
					onClick={handleBuy}
                    disabled={((isCreateReserva && total === 0) || hayImportePendiente)}
				>
					BUY
				</Button>
			</Box>

			<ModalPayment openModal={openModalPayment} handleClose={() => setOpenModalPayment(false)} />
			<ModalPaymentError openModal={openModalPaymentError} handleClose={() => setOpenModalPaymentError(false)} />
		</Grid>
	);
}
