import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { Nodo } from "../../models/routes";

type Props = {
	name: string;
	value: string;
	setValue: (value: string) => void;
	view: boolean;
	nodos: Nodo[];
	isVuelta: boolean;
	updateNodosSelect: (isVuelta: boolean, nodo: string) => void;
	error: boolean;
};

export function RouteSelect(props: Props) {
	return (
		<FormControl required sx={{ minWidth: 300, display: props.view ? "flex" : "none" }} error={props.error}>
			<InputLabel id={props.name}>{props.name}</InputLabel>
			<Select
				labelId={props.name}
				id={`select-route-${props.name}`}
				value={props.value}
				label={`${props.name} *`}
				onChange={(e) => {
					props.setValue(e.target.value);
					props.updateNodosSelect(props.isVuelta, e.target.value);
				}}
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{props.nodos.map((nodo: Nodo, index: number) => {
					return (
						<MenuItem key={index} value={nodo.codigo}>
							{nodo.descripcion}
						</MenuItem>
					);
				})}
			</Select>
			<FormHelperText>Required</FormHelperText>
		</FormControl>
	);
}
