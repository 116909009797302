import { LoadingButton } from "@mui/lab";
import { TextField, Grid } from "@mui/material";
import { useAppContext } from "../../contexts/AppContext";

type Props = {
	buscarPorLocalizador: (value: string) => void;
	errorMessage: string;
	setErrorMessage: (value: string) => void;
    loading: boolean;
};

export const ERROR_ENTER_BOOKING_NUMBER = "Please enter your booking number";

export function SearchLocalizador(props: Props) {
	
	const CORRECT_BOOKING_NUMBER = "Correct booking number";

	const WRONG_BOOKING_NUMBER = "Wrong booking number format";

	const { localizador, setLocalizador, booking, localizadorCorrecto, setLocalizadorCorrecto } = useAppContext();

	const changeBookingNumber = (value: string) => {
		const validLocalizador = new RegExp("^[A-Z0-9]{7}$");

		if (value.length < 7) {
			props.setErrorMessage(ERROR_ENTER_BOOKING_NUMBER);
			return;
		}

		if (value.length === 7 && validLocalizador.test(value)) {
			props.setErrorMessage(CORRECT_BOOKING_NUMBER);
			setLocalizadorCorrecto(true);
		} else {
			setLocalizadorCorrecto(false);
			props.setErrorMessage(WRONG_BOOKING_NUMBER);
		}
	};

	return (
		<Grid item xs={"auto"}>
			<TextField
				id="filled-helperText"
				label="Booking number"
				value={localizador}
				helperText={props.errorMessage}
				variant="filled"
				error={
					props.errorMessage === WRONG_BOOKING_NUMBER ||
					props.errorMessage === "Booking number NOT FOUND" ||
					(booking && booking.error && booking.error !== null
						? props.errorMessage === booking.error
						: props.errorMessage === "Booking number NOT FOUND")
				}
				onChange={(e) => {
					changeBookingNumber(e.target.value);
					setLocalizador(e.target.value);
				}}
			/>
			<LoadingButton
				sx={{
					ml: 2,
					mt: 1,
					borderRadius: "25px",
					fontSize: 16,
					width: 120,
				}}
				variant="contained"
				disabled={!localizadorCorrecto}
				onClick={() => props.buscarPorLocalizador(localizador)}
				className="Button"
				loading={props.loading}
                type="button"
			>
				SEARCH
			</LoadingButton>
		</Grid>
	);
}
