import { List, ListItem, ListItemText, Button, Chip } from "@mui/material";
import { DataLocalizador, ListLocalizadorResponse } from "../models/listLocalizador";

type Props = {
	selectName: (value: string) => void;
	listLocalizadorResponse: ListLocalizadorResponse;
};

const ListLocalizadores: React.FC<Props> = ({ selectName, listLocalizadorResponse }) => {
	return (
		<List
			sx={{
				border: 1,
				mt: 2,
				borderRadius: 1,
				borderColor: "primary.main",
				pt: 0,
				pb: 0,
				overflow: "auto",
				maxHeight: "600px",
                
			}}
		>
			{listLocalizadorResponse.listDataLocalizador.map((dataLocalizador: DataLocalizador, index) => {
				return (
					<ListItem
						key={index}
						sx={{
							borderBottom: index === listLocalizadorResponse.listDataLocalizador.length - 1 ? 0 : 1,
							borderColor: "primary.main",
                           
						}}
						secondaryAction={
							<Button
								sx={{
									borderRadius: "25px",
									fontSize: 12,
								}}
								variant="contained"
								onClick={() => selectName(dataLocalizador.localizador)}
							>
								Select
							</Button>
						}
					>
						<ListItemText
							primary={<Chip sx={{ fontWeight: "bold" }} label={dataLocalizador.localizador} />}
							secondary={
								<span
									style={{
										display: "flex",
										flexDirection: "column",
										marginLeft: "1rem",
										marginTop: "0.5rem",
									}}
								>
									<span>
										<span style={{ fontWeight: "bold" }}>Name:</span>{" "}
										{dataLocalizador.name.replace("|", " ")}
									</span>
									<span>
										<span style={{ fontWeight: "bold" }}>Identity Card:</span> {dataLocalizador.dni}
									</span>
								</span>
							}
						/>
					</ListItem>
				);
			})}
		</List>
	);
};

export default ListLocalizadores;