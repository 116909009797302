import axiosRetry from 'axios-retry';
import { PagoFinal } from "../models/booking";
import apiAxios from "./apiAxios";

const URL_PAYMENT = "/pagarReservaMaletas/";

axiosRetry(apiAxios, {
	retries: 3,
	retryCondition(error) {
		if(error.response?.status === 504) {
			return true;
		} else {
			return false;
		}
	},
	onRetry: (retryCount, error, requestConfig) => {
		requestConfig.headers = {
			Authorization: localStorage.getItem("token"),
			"Content-Type": "application/json",
		}
		console.log("RETRY COUNT: " + retryCount);
	}
})
export const paymentService = async (pagoFinal: PagoFinal) => {
	try {
		const result = await apiAxios.post(URL_PAYMENT, pagoFinal, {
			headers: {
				Authorization: localStorage.getItem("token"),
			},
		});
		console.log("DEVOLVEMOS RESULTADO -> " + result.data)
		return result.data;
	} catch(err) {
		console.log("ERROR -> " + err)
	}
};

