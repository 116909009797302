import { CreateReservaModel } from "../models/createReserva";
import apiAxios from "./apiAxios";

const CreateReserveService = async (value: CreateReservaModel) => {
	const result = await apiAxios.post(`/createReserve`, value, {
		headers: {
			Authorization:  localStorage.getItem("token"),
		},
	});

	return result.data;
};

export { CreateReserveService };