import { Servicios } from "../models/booking";
import apiAxios from "./apiAxios";

const URL_ADD_SERVICIOS = "/addServicios/";

export const addServiciosService = async (localizador: string, servicios: Servicios) => {
	const result = await apiAxios.post(`${URL_ADD_SERVICIOS}/${localizador}`, servicios, {
		headers: {
			Authorization: localStorage.getItem("token"),
		},
	});

	return result.data;
};
