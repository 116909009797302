import { Grid } from "@mui/material";
import { BuyReserva } from "./BuyReserva";
import { PrintLabels } from "./PrintLabels";

export function InfoReserva() {
	return (
		<Grid
			container
			spacing={1}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				mt: "20px",
			}}
		>
			<BuyReserva />
			<PrintLabels />
		</Grid>
	);
}
