import { Box, Button, Grid } from "@mui/material";
import { useAppContext } from "../../contexts/AppContext";
import { useEffect, useState } from "react";
import { Registro } from "../../models/registroMaletas";
import { validateToken, comprobarPrinted } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { ModalPrintLabel } from "../modals/ModalPrintLabel";
import { ModalNumberPrintLabel } from "../modals/ModalNumberPrintLabel";

export function PrintLabels() {
	const { isCreateReserva, registroMaletas, booking, printInboundChecked, printOutboundChecked, hayImportePendiente } = useAppContext();

	const [printButtonDisabled, setPrintButtonDisabled] = useState<boolean>(false);

	const navigate = useNavigate();

	const [openPrintViewModal, setOpenPrintViewModal] = useState<boolean>(false);

	const [openPrintNumberViewModal, setOpenPrintNumberViewModal] = useState<boolean>(false);

	const [numberCountPrint, setNumberCountPrint] = useState<string>("0");

	useEffect(() => {
		if (validateRegistro(registroMaletas.ida) || validateRegistro(registroMaletas.vuelta)) {
			setPrintButtonDisabled(true);
		} else {
			setPrintButtonDisabled(false);
		}
	}, [registroMaletas]);

	const validateRegistro = (registro: Registro): boolean => {
		if (
			registro.bag30 > registro.bag30Prev ||
			registro.bag60 > registro.bag60Prev ||
			registro.bagFree > registro.bagFreePrev
		) {
			return true;
		}

		return false;
	};

	const validateRegistroMayorCero = (registro: Registro): boolean => {
		if (registro.bag30 > 0 || registro.bag60 > 0 || registro.bagFree > 0) {
			return true;
		}

		return false;
	};

	const imprimir = () => {
		validateToken();
		var numero = comprobarPrinted(booking.localizador, registroMaletas);
		numero.then((contNumero) => {
			if (booking.localizador &&
				(validateRegistroMayorCero(registroMaletas.ida) || validateRegistroMayorCero(registroMaletas.vuelta)) &&
				((booking.vuelta && booking.vuelta !== null && printInboundChecked) || printOutboundChecked) && contNumero === 1) {
				navigate("/printBaggageTags");
			} else if(contNumero !== 1) {
				setNumberCountPrint(contNumero);
				setOpenPrintNumberViewModal(true);
			} else {
				setOpenPrintViewModal(true);
			}
		})
	
	};

	return (
		<Grid item xs="auto">
			<Box sx={{ flexDirection: "row", ml: 5 }}>
				<Box sx={{ display: "flex", flexDirection: "row" }}></Box>
				<Button
					sx={{ borderRadius: "25px", fontSize: 16, width: 180 }}
					variant="contained"
					disabled={printButtonDisabled || (isCreateReserva && !printButtonDisabled) || hayImportePendiente}
					onClick={imprimir}
				>
					PRINT LABELS
				</Button>

				<ModalPrintLabel
					openModal={openPrintViewModal}
					handleClose={() => {
						setOpenPrintViewModal(false);
					}}
				/>
				<ModalNumberPrintLabel
					openModal = {openPrintNumberViewModal}
					handleClose={() => {
						setOpenPrintNumberViewModal(false);
					}}
					openPrintView={() => {
						setOpenPrintNumberViewModal(false);
						navigate("/printBaggageTags");
					}}
					times={numberCountPrint}
				/>
			</Box>
		</Grid>
	);
}
