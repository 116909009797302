import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import BaggageTag from "../components/BaggageTag";
import { useAppContext } from "../contexts/AppContext";
import { Routes } from "../models/masters";

const longEnUSFormatter = new Intl.DateTimeFormat("en-US", {
	year: "numeric",
	month: "long",
	day: "numeric",
	timeZone: "UTC"
});

type Props = {};

const PrintBaggageTicket = (props: Props) => {
	const {
		booking,
		registroMaletas,
		printInboundChecked,
		setPrintInboundChecked,
		printOutboundChecked,
		setPrintOutboundChecked,
        setOpenPendingPaymentModal
	} = useAppContext();

	const printableAreaRef = React.useRef<HTMLDivElement>(null);

	const baggage = [];

	const navigate = useNavigate();

    var pasajeroResponsable = booking.listaPasajeros?.length > 0 ? booking.listaPasajeros[0].nombre : "";

	const getDescripcionRuta = (code: string): string => {
		if(code !== null && code !== "") {
			return Routes.filter((route) => route.code === code)[0].descripcion;
		}
	  
		return "";
	};

	const convertFecha = (fecha: string) => {
        if(fecha !== null && fecha !== "") {
            return longEnUSFormatter.format(new Date(new Date(fecha).toUTCString()));
        }
		
        return "";
	};

	if (printOutboundChecked === true) {
		var bag30Ida = registroMaletas.ida.bag30;
		var bag60Ida = registroMaletas.ida.bag60;
		var bagFreeIda = registroMaletas.ida.bagFree;
        

		for (let i = 1; i <= bag30Ida; i++) {
			baggage.push(
				<div key={`bag30Ida-${i}`}>
					<BaggageTag
						contMaletas={"frontTag" + i}
						numMaleta={i}
						nombreMaleta="BAG30"
						numMaletas={bag30Ida}
						origenDestino={getDescripcionRuta(booking.ida.origen) + "-" + getDescripcionRuta(booking.ida.destino)}
						fecha={convertFecha(booking.ida.fechaSalida)}
						localizador={booking.localizador}
						pasajeroResponsable={pasajeroResponsable}
						tipoMaleta="SMALL BAGGAGE"
					/>
					<BaggageTag
						contMaletas={"frontTag" + i}
						numMaleta={i}
						nombreMaleta="BAG30"
						numMaletas={bag30Ida}
						origenDestino={getDescripcionRuta(booking.ida.origen) + "-" + getDescripcionRuta(booking.ida.destino)}
						fecha={convertFecha(booking.ida.fechaSalida)}
						localizador={booking.localizador}
						pasajeroResponsable={pasajeroResponsable}
						tipoMaleta="SMALL BAGGAGE"
					/>
				</div>
				
			);
		}
		for (let i = 1; i <= bag60Ida; i++) {
			baggage.push(
				<div key={`bag60Ida-${i}`}>
					<BaggageTag
						contMaletas={"frontTag" + i}
						numMaleta={i}
						nombreMaleta="BAG60"
						numMaletas={bag60Ida}
						origenDestino={getDescripcionRuta(booking.ida.origen) + "-" + getDescripcionRuta(booking.ida.destino)}
						fecha={convertFecha(booking.ida.fechaSalida)}
						localizador={booking.localizador}
						pasajeroResponsable={pasajeroResponsable}
						tipoMaleta="BIG BAGGAGE"
					/>
					<BaggageTag
						contMaletas={"frontTag" + i}
						numMaleta={i}
						nombreMaleta="BAG60"
						numMaletas={bag60Ida}
						origenDestino={getDescripcionRuta(booking.ida.origen) + "-" + getDescripcionRuta(booking.ida.destino)}
						fecha={convertFecha(booking.ida.fechaSalida)}
						localizador={booking.localizador}
						pasajeroResponsable={pasajeroResponsable}
						tipoMaleta="BIG BAGGAGE"
					/>
				</div>
			);
		}
		for (let i = 1; i <= bagFreeIda; i++) {
			baggage.push(
				<div key={`bagFreeIda-${i}`}>
					<BaggageTag
						contMaletas={"frontTag" + i}
						numMaleta={i}
						nombreMaleta="BAGFREE"
						numMaletas={bagFreeIda}
						origenDestino={getDescripcionRuta(booking.ida.origen) + "-" + getDescripcionRuta(booking.ida.destino)}
						fecha={convertFecha(booking.ida.fechaSalida)}
						localizador={booking.localizador}
						pasajeroResponsable={pasajeroResponsable}
						tipoMaleta="FREE BAGGAGE"
					/>
					<BaggageTag
						contMaletas={"frontTag" + i}
						numMaleta={i}
						nombreMaleta="BAGFREE"
						numMaletas={bagFreeIda}
						origenDestino={getDescripcionRuta(booking.ida.origen) + "-" + getDescripcionRuta(booking.ida.destino)}
						fecha={convertFecha(booking.ida.fechaSalida)}
						localizador={booking.localizador}
						pasajeroResponsable={pasajeroResponsable}
						tipoMaleta="FREE BAGGAGE"
					/>
				</div>
			);
		}
	}

	if (printInboundChecked === true) {
		var bag30Vuelta = registroMaletas.vuelta.bag30;
		var bag60Vuelta = registroMaletas.vuelta.bag60;
		var bagFreeVuelta = registroMaletas.vuelta.bagFree;

		for (let i = 1; i <= bag30Vuelta; i++) {
			baggage.push(
				<div key={`bag30Vuelta-${i}`}>
					<BaggageTag
						contMaletas={"frontTag" + i}
						numMaleta={i}
						nombreMaleta="BAG30"
						numMaletas={bag30Vuelta}
						origenDestino={getDescripcionRuta(booking.vuelta.origen) + "-" + getDescripcionRuta(booking.vuelta.destino)}
						fecha={convertFecha(booking.vuelta.fechaSalida)}
						localizador={booking.localizador}
						pasajeroResponsable={pasajeroResponsable}
						tipoMaleta="SMALL BAGGAGE"
					/>
					<BaggageTag
						contMaletas={"frontTag" + i}
						numMaleta={i}
						nombreMaleta="BAG30"
						numMaletas={bag30Vuelta}
						origenDestino={getDescripcionRuta(booking.vuelta.origen) + "-" + getDescripcionRuta(booking.vuelta.destino)}
						fecha={convertFecha(booking.vuelta.fechaSalida)}
						localizador={booking.localizador}
						pasajeroResponsable={pasajeroResponsable}
						tipoMaleta="SMALL BAGGAGE"
					/>
				</div>
			);
		}

		for (let i = 1; i <= bag60Vuelta; i++) {
			baggage.push(
				<div key={`bag60Vuelta-${i}`}>
					<BaggageTag
						contMaletas={"frontTag" + i}
						numMaleta={i}
						nombreMaleta="BAG60"
						numMaletas={bag60Vuelta}
						origenDestino={getDescripcionRuta(booking.vuelta.origen) + "-" + getDescripcionRuta(booking.vuelta.destino)}
						fecha={convertFecha(booking.vuelta.fechaSalida)}
						localizador={booking.localizador}
						pasajeroResponsable={pasajeroResponsable}
						tipoMaleta="BIG BAGGAGE"
					/>
					<BaggageTag
						contMaletas={"frontTag" + i}
						numMaleta={i}
						nombreMaleta="BAG60"
						numMaletas={bag60Vuelta}
						origenDestino={getDescripcionRuta(booking.vuelta.origen) + "-" + getDescripcionRuta(booking.vuelta.destino)}
						fecha={convertFecha(booking.vuelta.fechaSalida)}
						localizador={booking.localizador}
						pasajeroResponsable={pasajeroResponsable}
						tipoMaleta="BIG BAGGAGE"
					/>
				</div>
			);
		}

		for (let i = 1; i <= bagFreeVuelta; i++) {
			baggage.push(
				<div key={`bagFreeVuelta-${i}`}>
					<BaggageTag
						contMaletas={"frontTag" + i}
						numMaleta={i}
						nombreMaleta="BAGFREE"
						numMaletas={bagFreeVuelta}
						origenDestino={getDescripcionRuta(booking.vuelta.origen) + "-" + getDescripcionRuta(booking.vuelta.destino)}
						fecha={convertFecha(booking.vuelta.fechaSalida)}
						localizador={booking.localizador}
						pasajeroResponsable={pasajeroResponsable}
						tipoMaleta="FREE BAGGAGE"
					/>
					<BaggageTag
						contMaletas={"frontTag" + i}
						numMaleta={i}
						nombreMaleta="BAGFREE"
						numMaletas={bagFreeVuelta}
						origenDestino={getDescripcionRuta(booking.vuelta.origen) + "-" + getDescripcionRuta(booking.vuelta.destino)}
						fecha={convertFecha(booking.vuelta.fechaSalida)}
						localizador={booking.localizador}
						pasajeroResponsable={pasajeroResponsable}
						tipoMaleta="FREE BAGGAGE"
					/>
				</div>
			);
		}
	}
	useEffect(() => {
		let token = localStorage.getItem("token");
		if(token == null) {
			navigate("/")
		} else {
			const intervalModalCarga = setInterval(() => {
				handlePrintClick();
				clearInterval(intervalModalCarga);
			}, 500);
		}
	}, [navigate]);

	const handlePrintClick = () => {
		const intervalModalCarga = setInterval(() => {
			window.print();
			clearInterval(intervalModalCarga);
		}, 500);
	};

	const handleGoBackClick = () => {
		setPrintInboundChecked(true);
		setPrintOutboundChecked(true);
		navigate("/home");
        if(booking.importePendiente !== null && booking.importePendiente !== 0) {
            setOpenPendingPaymentModal(true);
        }
	};

	return (
		<>
			<Box
				className="noprint"
				onClick={handleGoBackClick}
				sx={{
					backgroundColor: "#008193",
					width: "2em",
					height: "2em",
					cursor: "pointer",
					position: "fixed",
					zIndex: 999,
					top: 0,
				}}
			>
				<NavigateBeforeIcon sx={{ color: "#FFF" }}>Ir Atrás</NavigateBeforeIcon>
			</Box>
			<div style={{ float: "none" }} ref={printableAreaRef}>
				{baggage}
			</div>
		</>
	);
};

export default PrintBaggageTicket;
