import { LoadingButton } from "@mui/lab";
import { TextField, Grid } from "@mui/material";
import { useState } from "react";
import { ListLocalizadorResponse } from "../../models/listLocalizador";
import { validateToken } from "../../services/authService";
import { useAppContext } from "../../contexts/AppContext";
import { searchPassengerService } from "../../services/searchService";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { initBooking } from "../../models/booking";
import { useNavigate } from "react-router-dom";

export function SearchNamePassenger() {
  const ERROR_ENTER_NAME_PASSENGER = "Please enter the passenger's name";
  const ERROR_ENTER_BOOKING_NUMBER = "Please enter your booking number";
  const {
    setIsVisualizeBooking,
    listLocalizadorResponse,
    setListLocalizadorResponse,
  } = useAppContext();

  const longEnUSFormatter = new Intl.DateTimeFormat("en-US", {
	year: "2-digit",
	month: "2-digit",
	day: "2-digit",
	timeZone: "UTC"
});


  const [loading, setLoading] = useState<boolean>(false);

  const [error, setError] = useState<string>(ERROR_ENTER_NAME_PASSENGER);

  const [searchPassenger, setSearchPassenger] = useState<string>("");

  const [searchDate, setSearchDate] = useState<string>(longEnUSFormatter.format(new Date()).replaceAll("/", "-"));

  const [fechaOrigen] = useState<Dayjs | null>(dayjs(longEnUSFormatter.format(new Date())));

  const {validName, setValidName, setIsCreateReserva, setBooking} = useAppContext();

  const {setErrorMessageLocalizador, setLocalizador} =  useAppContext();

  const navigate = useNavigate();

  const searchName = () => {
    setIsCreateReserva(false);
    setIsVisualizeBooking(false);
    setLoading(true);
    setBooking(initBooking);
    setErrorMessageLocalizador(ERROR_ENTER_BOOKING_NUMBER);
    setLocalizador("");
    validateToken();

    const result = searchPassengerService(searchPassenger, searchDate);
    result
      .then((res) => {
        const listLocalizadorResponse: ListLocalizadorResponse = res;
        console.log(listLocalizadorResponse);
        setListLocalizadorResponse(listLocalizadorResponse);

        if (listLocalizadorResponse.error != null) {
          setError(listLocalizadorResponse.error);
          return;
        }
      }).catch(function() {
				localStorage.clear();
				navigate("/");
      }).finally(() => {
        setLoading(false);
        // setValidName(false);
      });
  };

  return (
    <Grid item xs={"auto"}>
      <TextField
        id="filled-helperText"
        label="Passenger's name"
        variant="filled"
        value={searchPassenger}
        helperText={error}
        error={
          listLocalizadorResponse?.error !== null &&
          error === listLocalizadorResponse?.error
        }
        onChange={(e) => {
          setSearchPassenger(e.target.value);
          setValidName(e.target.value.length > 0);
          setError(ERROR_ENTER_NAME_PASSENGER);
        }}
      />
	  <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
		sx={{ maxWidth: 250, display: "flex", mt: 3 }}
		label="Select date of departure"
		value={fechaOrigen}
        onChange={(e) => {
			setSearchDate(e?.format("MM-DD-YY")!);
		}}
      />
	  </LocalizationProvider>
      <LoadingButton
        sx={{
          mt: 2,
          borderRadius: "25px",
          fontSize: 16,
          width: 120,
        }}
        variant="contained"
        className="Button"
        disabled={!validName}
        onClick={() => searchName()}
        loading={loading}
      >
        SEARCH
      </LoadingButton>
    </Grid>
  );
}
