import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

type Props = {
	openModal: boolean;
	handleClose: () => void;
};

export function ModalPrintLabel(props: Props) {
	return (
		<Dialog
			open={props.openModal}
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{"Not Allowed Operation"}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<p>In order to print the baggage tags this conditions must be fulfilled:</p>
					<br />
					<ul>
						<li>Check the number of baggages selected. There should be one at least.</li>
						<li>Check that at least one journey is selected.</li>
					</ul>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={props.handleClose}
					autoFocus
					sx={{ borderRadius: "25px", fontSize: 16, width: 180 }}
					variant="contained"
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}
