import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

type Props = {
	openModal: boolean;
	handleClose: () => void;
	errorMessage: string;
};

export function ModalPaymentFinalError(props: Props) {
	return (
		<Dialog
			open={props.openModal}
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					There has been an error with the payment process.
					<br />
					{props.errorMessage}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={props.handleClose}
					autoFocus
					sx={{ borderRadius: "25px", fontSize: 16, width: 180 }}
					variant="contained"
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}
