import apiAxios from "./apiAxios";

export const searchPassengerService = async (value: string, fecha : string) => {
	const sesion = localStorage.getItem("token");

	const result = await apiAxios.get(`/search/${value}/${fecha}`, {
		headers: {
			Authorization: sesion,
		},
	});

	return result.data;
};

export const searchLocalizadorService = async (localizador: string) => {
	const sesion = localStorage.getItem("token");

	const result = await apiAxios.get(`/${localizador}`, {
		headers: {
			Authorization: sesion,
		},
	});

	return result.data;
};
