export type Booking = {
	localizador: string;
	importePendiente: number;
	ida: {
		servicio: string;
		orden: number;
		origen: string;
		destino: string;
		fechaSalida: string;
		hora: number;
		listaServiciosExtra: ListaServiciosExtra;
	};
	vuelta: {
		servicio: string;
		orden: number;
		origen: string;
		destino: string;
		fechaSalida: string;
		hora: number;
		listaServiciosExtra: ListaServiciosExtra;
	};
	listaPasajeros: Pasajero[],
	error: string;
};

export const initBooking: Booking = {
    localizador: "",
    importePendiente: 0,
    ida: {
        servicio: "",
        orden: 0,
        origen: "",
        destino: "",
        fechaSalida: "",
        hora: 0,
        listaServiciosExtra: {} as ListaServiciosExtra
    },
    vuelta: {
        servicio: "",
        orden: 0,
        origen: "",
        destino: "",
        fechaSalida: "",
        hora: 0,
        listaServiciosExtra: {} as ListaServiciosExtra
    },
    listaPasajeros: [] as Pasajero[],
    error: ""
}

export type Pasajero = {
    nombre: string
}

export type ListaServiciosExtra = {
	maletas30: number;
	maletas60: number;
	maletasFree: number;
};

export type Servicios = {
	serviciosIda: Servicio[],
	serviciosVuelta: Servicio[]
};

export type Servicio = {
	servicio: string;
	orden: number;
	fecha: string;
	hora: number;
	grupo: string;
	clase: string;
	cantidad: number;
	infoExtra: string;
	origen: string;
};

export type PagoFinal = {
	localizador: string;
	formaPago: string;
	importe: number;
	usuario: string;
	numConfirmacion: string;
};

export type PagoFinalResponse = {
	resultado: string;
	textoError: string;
};
