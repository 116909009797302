import React from "react";
import logo from "../CARIBBEAN_BALEARIA_LOGO.png";
import Barcode from "react-barcode";
type Props = {
  contMaletas: string;
  numMaleta: number;
  nombreMaleta: string;
  numMaletas: number;
  origenDestino: string;
  fecha: string;
  localizador: string;
  tipoMaleta: string;
  pasajeroResponsable: string;
};

const BaggageTag = ({
  contMaletas,
  numMaleta,
  nombreMaleta,
  numMaletas,
  origenDestino,
  fecha,
  localizador,
  tipoMaleta,
  pasajeroResponsable,
}: Props) => {
  return (
    <div
      key={contMaletas}
      style={{
        width: "1690px",
        height: "220px",
        marginLeft: "1.5em",
        display: "flex",
      }}
    >
      <div
        style={{
          width: 215,
          height: 200,
          transform: "rotate(-0.25turn)",
        }}
      >
        <span
          style={{
            marginLeft: "35px",
          }}
        >
          <img alt="logo_caribbean" src={logo} width={120} height={35} />
        </span>
        <span
          style={{
            width: "270px",
            display: "flex",
            fontSize: "12px",
            lineHeight: "25px",
            marginLeft: "50px",
            color: "#000"
          }}
        >
          {pasajeroResponsable}
        </span>
        <div>
          <Barcode
            value={localizador + "#" + numMaleta + "of" + numMaletas}
            width={1}
            height={90}
            fontSize={12}
            marginTop={pasajeroResponsable !== null && pasajeroResponsable !== ""? 1: 10}
          ></Barcode>
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "10px"}}>
        <div
          style={{
            fontFamily: "Arial",
            fontWeight: "bolder",
            textTransform: "uppercase",
            lineHeight: "1px",
            margin: 20,
            marginLeft: 15,
            width: "508px"
          }}
        >
          <img
            alt="logo_caribbean"
            src={logo}
            width={150}
            style={{ marginBottom: 30 }}
          />
          <p style={{ fontSize: "36px", lineHeight: "12px" }}>
            {origenDestino}
          </p>
          <p style={{ lineHeight: "10px" }}>{fecha}</p>
          <>
            {numMaletas > 0 && (
              <p style={{ lineHeight: "15px" }}>{tipoMaleta}</p>
            )}
          </>
        </div>
        <div
          style={{
            fontFamily: "Arial",
            fontWeight: "bolder",
            textTransform: "uppercase",
            lineHeight: "1px",
            margin: 20,
            marginLeft: 20,
            width: "508px"
          }}
        >
          <img
            alt="logo_caribbean"
            src={logo}
            width={150}
            style={{ marginBottom: 30 }}
          />
          <p style={{ fontSize: "36px", lineHeight: "12px" }}>
            {origenDestino}
          </p>
          <p style={{ lineHeight: "10px" }}>{fecha}</p>
          <>
            {numMaletas > 0 && (
              <p style={{ lineHeight: "15px" }}>{tipoMaleta}</p>
            )}
          </>
        </div>
      </div>
      <div
        style={{
          width: 215,
          height: 200,
          transform: "rotate(-0.25turn)",
        }}
      >
        <span
          style={{
            marginLeft: "35px",
          }}
        >
          <img alt="logo_caribbean" src={logo} width={120} height={35} />
        </span>
        <span
          style={{
            width: "270px",
            display: "flex",
            fontSize: "12px",
            lineHeight: "25px",
            marginLeft: "50px",
            color: "#000"
          }}
        >
          {pasajeroResponsable}
        </span>
        <div>
          <Barcode
            value={localizador + "#" + numMaleta + "of" + numMaletas}
            width={1}
            height={90}
            fontSize={12}
            marginTop={pasajeroResponsable !== null && pasajeroResponsable !== ""? 1: 10}
          ></Barcode>
        </div>
      </div>
    </div>
  );
};

export default BaggageTag;
