import { RequestHoraSalida } from "../models/horaSalidaModel";
import apiAxios from "./apiAxios";

export const GetHoraSalidas = async (requestHoraSalida: RequestHoraSalida) => {

	const result = await apiAxios.post(`/ruta/hora`, requestHoraSalida, {
		headers: {
			Authorization: localStorage.getItem("token"),
		},
	});

	return result.data;
};