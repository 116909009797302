import { LoadingButton } from "@mui/lab";
import { Box, FormControl, FormControlLabel, FormLabel, Modal, Radio, RadioGroup, TextField } from "@mui/material";
import { useState } from "react";
import { validateToken } from "../../services/authService";
import { BAG_FREE_FLL, BAG_FREE_FPO } from "../../models/masters";
import { Booking, PagoFinal, PagoFinalResponse, Servicio, Servicios } from "../../models/booking";
import { useAppContext } from "../../contexts/AppContext";
import { Registro } from "../../models/registroMaletas";
import { addServiciosService } from "../../services/extrasService";
import { paymentService } from "../../services/paymentService";
import { ModalPaymentFinalError } from "./ModalPaymentFinalError";
import { useNavigate } from "react-router-dom";
import { CreateReservaModel, Maletas, ResponseCreateReserva } from "../../models/createReserva";
import { CreateReserveService } from "../../services/createReserveService";
import { searchLocalizadorService } from "../../services/searchService";

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #008193",
	boxShadow: 24,
	p: 4,
	textAlign: "center",
};

const longEnUSFormatter = new Intl.DateTimeFormat("en-US", {
	year: "2-digit",
	month: "2-digit",
	day: "2-digit",
	timeZone: "UTC"
});

type Props = {
	openModal: boolean;
	handleClose: () => void;
};

export function ModalPayment(props: Props) {
	const CREDIT_CARD = "CREDIT CARD";

	const TPV = "TPV";

	const CASH = "CASH";

	const navigate = useNavigate();

	const {
		booking,
		registroMaletas,
		total,
		isCreateReserva,
		createReserva,
		setBooking,
		setLocalizador,
		setIsCreateReserva,
		setIsSelectTrip,
		setTotal,
		setRegistroMaletas,
	} = useAppContext();

	const [selectRadio, setSelectRadio] = useState<boolean>(false);

	const [radioValue, setRadioValue] = useState<string>(CASH);

	const [loading, setLoading] = useState<boolean>(false);

	const [hideBackDrop, setHideBackDrop] = useState<boolean>(false);

	const [openCardNumberView, setOpenCardNumberView] = useState<boolean>(false);

	const [cardAuthNumber, setCardAuthNumber] = useState<string>("");

	const [finalBuyDisabled, setFinalBuyDisabled] = useState<boolean>(false);

	const [openModalPaymentFinalError, setOpenModalPaymentFinalError] = useState<boolean>(false);

	const [errorMessageFinalBuy, setErrorMessageFinalBuy] = useState<string>("");

	const handleChangeRadioBuy = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value;
		setRadioValue(value);
		if (value === CREDIT_CARD || value === TPV) {
			setFinalBuyDisabled(true);
			setOpenCardNumberView(true);
		} else if (value === CASH) {
			var receiptNumber: any = document.getElementById("receiptNumber");
			receiptNumber.value = "";
			setCardAuthNumber("");
			setFinalBuyDisabled(false);
			setOpenCardNumberView(false);
		}
	};

	const finalBuy = () => {
		validateToken();
		var user = localStorage.getItem("user") !== null ? localStorage.getItem("user") : "";
		// console.log(user);

		if (user === "") {
			// console.log("Se ha perdido el usuario");
			return;
		}

		let servicios: Servicios = {
			serviciosIda: buildListaServicio(registroMaletas.ida, false),
			serviciosVuelta: buildListaServicio(registroMaletas.vuelta, true),
		};

		// console.log(servicios);

		const resultAddServicios = addServiciosService(booking.localizador, servicios);

		setLoading(true);
		setHideBackDrop(true);
		setSelectRadio(true);
		resultAddServicios
			.then((data) => {
				if (data === "OK") {
					pagar(user!, booking.localizador, booking);
				} else {
					finish();
					setOpenModalPaymentFinalError(true);
					setErrorMessageFinalBuy(data + "-ERROR1 - Could not store baggage on booking");
				}
			})
			.catch((e: any) => {
				finish();
				setOpenModalPaymentFinalError(true);
				setErrorMessageFinalBuy(e + "-CATCH-ERROR1 - Could not store baggage on booking");
			})
			.finally(() => {});
	};

	const pagar = (user: string, localizador: string, booking: Booking) => {
		var formaPago = "";

		if (radioValue === CREDIT_CARD || radioValue === TPV) {
			formaPago = radioValue === TPV ? TPV : "VISA";
		} else if (radioValue === CASH) {
			formaPago = booking.ida.origen === "FLL"? "CONT" : "CONBD";
		}

		var pagoFinal: PagoFinal = buildPagoFinal(formaPago, user!, localizador);
		// console.log(pagoFinal);

		var resultPayment = paymentService(pagoFinal);

		resultPayment
			.then((data) => {
				var pagoFinalResponse: PagoFinalResponse = data;

				if (pagoFinalResponse.resultado === "OK") {
					if (isCreateReserva) {
						const resultGetLocalizador = searchLocalizadorService(localizador);
						resultGetLocalizador
							.then((data) => {
								var booking: Booking = data;
								setBooking(booking);
								setLocalizador(localizador);
								setIsCreateReserva(false);
								setIsSelectTrip(false);
								setTotal(0);
								updateRegistro();
							})
							.finally(() => {
								navigate("/printBaggageTags");
							});
					} else {
						setTotal(0);
						updateRegistro();
						navigate("/printBaggageTags");
					}
				} else {
					setOpenModalPaymentFinalError(true);
					setErrorMessageFinalBuy(
						pagoFinalResponse.resultado +
							"-" +
							pagoFinalResponse.textoError.trim() +
							"-ERROR2 - Could not fulfill payment"
					);
				}
			})
			.catch((e: any) => {
				finish();
				setOpenModalPaymentFinalError(true);
				setErrorMessageFinalBuy(e + "-CATCH-ERROR2 - Could not fulfill payment");
			})
			.finally(() => {
				window.setTimeout(() => {
					finish();
				}, 7000);
			});
	};

	const updateRegistro = () => {
		setRegistroMaletas({
			...registroMaletas,
			ida: {
				...registroMaletas.ida,
				bag30Prev: registroMaletas.ida.bag30,
				bag60Prev: registroMaletas.ida.bag60,
				bagFreePrev: registroMaletas.ida.bagFree,
			},
			vuelta: {
				...registroMaletas.vuelta,
				bag30Prev: registroMaletas.vuelta.bag30,
				bag60Prev: registroMaletas.vuelta.bag60,
				bagFreePrev: registroMaletas.vuelta.bagFree,
			},
		});
	};

	const finish = () => {
		setLoading(false);
		setHideBackDrop(false);
		setSelectRadio(false);
		setOpenCardNumberView(false);
	};

	const buildPagoFinal = (formaPago: string, user: string, localizador: string): PagoFinal => {
		return {
			localizador: localizador,
			formaPago: formaPago,
			importe: total,
			usuario: user,
			numConfirmacion: cardAuthNumber,
		};
	};

	const buildListaServicio = (registro: Registro, isVuelta: boolean): Servicio[] => {
		let listaServiciosIda: Servicio[] = [] as Servicio[];

		if (registro.bag30 > registro.bag30Prev) {
			listaServiciosIda = [...listaServiciosIda, buildServicio(isVuelta, "BAG30", registro.bag30, registro.bag30Prev)];
		}

		if (registro.bag60 > registro.bag60Prev) {
			listaServiciosIda = [...listaServiciosIda, buildServicio(isVuelta, "BAG60", registro.bag60, registro.bag60Prev)];
		}

		if (registro.bagFree > registro.bagFreePrev) {
			var clase = isVuelta ? getCodeBagFree(booking.vuelta.origen) : getCodeBagFree(booking.ida.origen);
			listaServiciosIda = [
				...listaServiciosIda,
				buildServicio(isVuelta, clase, registro.bagFree, registro.bagFreePrev),
			];
		}

		return listaServiciosIda;
	};

	const buildServicio = (isVuelta: boolean, clase: string, bag: number, bagPrev: number): Servicio => {
		return {
			servicio: isVuelta ? booking.vuelta.servicio : booking.ida.servicio,
			orden: isVuelta ? booking.vuelta.orden : booking.ida.orden,
			fecha: isVuelta
				? longEnUSFormatter.format(new Date(new Date(booking.vuelta.fechaSalida).toUTCString()))
				: longEnUSFormatter.format(new Date(new Date(booking.ida.fechaSalida).toUTCString())),
			hora: isVuelta ? booking.vuelta.hora : booking.ida.hora,
			grupo: "X2",
			clase: clase,
			cantidad: +(bag - bagPrev),
			infoExtra: "",
			origen: "MALET",
		};
	};

	const getCodeBagFree = (code: string): string => {
		if (code === "FLL") {
			return BAG_FREE_FLL;
		} else if (code === "FPO") {
			return BAG_FREE_FPO;
		}

		return "";
	};

	const close = () => {
		setOpenCardNumberView(false);
		props.handleClose();
	};

	const createReservaBuy = () => {
		// console.log("Creamos reserva y sacamos localizador");

		const createReserveUpdate: CreateReservaModel = {
			...createReserva,
			origen: booking.ida.origen,
			destino: booking.ida.destino,
			fechaIda: longEnUSFormatter.format(new Date(new Date(booking.ida.fechaSalida).toUTCString())),
			fechaVuelta: booking.vuelta.fechaSalida && longEnUSFormatter.format(new Date(new Date(booking.vuelta?.fechaSalida).toUTCString())),
			maletasIda: buildMaletas(registroMaletas.ida),
			maletasVuelta: buildMaletas(registroMaletas.vuelta),
		};

		// console.log(createReserveUpdate);

		var user = localStorage.getItem("user") !== null ? localStorage.getItem("user") : "";
		// console.log(user);

		if (user === "") {
			// console.log("Se ha perdido el usuario");
			return;
		}

		setLoading(true);
		setHideBackDrop(true);
		setSelectRadio(true);
		const result = CreateReserveService(createReserveUpdate);
		result
			.then((data) => {
				var responseCreateReserva: ResponseCreateReserva = data;
				// console.log(responseCreateReserva);

				if (responseCreateReserva.result.result === "OK") {
					pagar(user!, responseCreateReserva.localizador, booking);
				} else {
					finish();
					setOpenModalPaymentFinalError(true);
					setErrorMessageFinalBuy(
						responseCreateReserva.result.descripcion + "-CATCH-ERROR1 - Could not create booking"
					);
				}
			})
			.catch((e: any) => {
				finish();
				setOpenModalPaymentFinalError(true);
				setErrorMessageFinalBuy(e + "-CATCH-ERROR1 - Could not create booking");
			})
			.finally(() => {});
	};

	const buildMaletas = (registro: Registro): Maletas => {
		return {
			maletas30: registro.bag30,
			maletas60: registro.bag60,
			maletasFree: registro.bagFree,
		};
	};

	return (
		<>
			<Modal
				open={props.openModal}
				onClose={close}
				hideBackdrop={hideBackDrop}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<FormControl>
						<FormLabel id="demo-radio-buttons-group-label" sx={{ color: "#000", fontWeight: "bold" }}>
							Choose Payment Option
						</FormLabel>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							defaultValue={CASH}
							name="radio-buttons-group"
							onChange={handleChangeRadioBuy}
						>
							<FormControlLabel value={CASH} control={<Radio />} label={CASH} disabled={selectRadio} />
							<FormControlLabel value={CREDIT_CARD} control={<Radio />} label={CREDIT_CARD} disabled={selectRadio} />

							<FormControlLabel value={TPV} control={<Radio />} label={TPV} disabled={selectRadio} />
						</RadioGroup>
					</FormControl>
					<TextField
						id="receiptNumber"
						variant="filled"
						label="Receipt Number"
						hidden={!openCardNumberView}
						onChange={(e) => {
							setCardAuthNumber(e.target.value);
							if (e.target.value !== "") {
								setFinalBuyDisabled(false);
							}
						}}
					></TextField>
					<LoadingButton
						sx={{
							borderRadius: "25px",
							fontSize: 16,
							width: 180,
							padding: 1,
							margin: 1,
						}}
						variant="contained"
						loading={loading}
						onClick={() => {
							if (isCreateReserva) {
								createReservaBuy();
							} else {
								finalBuy();
							}
						}}
						disabled={finalBuyDisabled}
					>
						BUY
					</LoadingButton>
				</Box>
			</Modal>
			<ModalPaymentFinalError
				openModal={openModalPaymentFinalError}
				handleClose={() => setOpenModalPaymentFinalError(false)}
				errorMessage={errorMessageFinalBuy}
			/>
		</>
	);
}
