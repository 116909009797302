import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
	open: boolean;
};

export default function Loader(props: Props) {
	return (
		<Backdrop
			sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={props.open}
		>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
}
