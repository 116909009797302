import { RegistroMaletas } from "../models/registroMaletas";
import apiAxios from "./apiAxios";


const API_URL_AUTH = "/authenticate";

const API_URL_VALIDATE_TOKEN = "/validate/token";

const API_URL_COMPROBAR_PRINTED = "/printNumber";

export const auth = async (username: string, password: string) => {
	const response = await apiAxios.post(API_URL_AUTH, {
		username,
		password,
	});
	// console.log("token -> " + response.data.jwt);
	if (response.data) {
		localStorage.setItem("token", response.data.jwt);
	}
	return response.data;
};

export const validateToken = async () => {
	var token = localStorage.getItem("token") ? localStorage.getItem("token") : "";

	if (token === "") {
		localStorage.clear();
		(window as Window).location = "/";
		return;
	}

	apiAxios
		.get(API_URL_VALIDATE_TOKEN, {
			headers: {
				Authorization: token,
			},
		})
		.catch(() => {
            // console.log("Error");
			localStorage.clear();
			(window as Window).location = "/";
		});
};

export const comprobarPrinted = async (localizador: string, registroMaletas: RegistroMaletas) => {
	var token = localStorage.getItem("token") ? localStorage.getItem("token") : "";
	var numMaletas = registroMaletas.ida.bag30 + registroMaletas.ida.bag60 + registroMaletas.ida.bagFree
		+ registroMaletas.vuelta.bag30 + registroMaletas.vuelta.bag60 + registroMaletas.vuelta.bagFree;
	const resultado = await apiAxios.get(API_URL_COMPROBAR_PRINTED + `/${localizador}/${numMaletas}`, {
		headers: {
			Authorization: token,
		},
	});
	return resultado.data;
}

export const logout = () => {
	localStorage.removeItem("token");
};

export const getCurrentUser = () => {
	const userStr = localStorage.getItem("token");
	if (userStr) return JSON.parse(userStr);

	return null;
};
