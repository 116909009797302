export type Route = {
	code: string;
	descripcion: string;
};

export const Routes: Route[] = [
	{
		code: "FLL",
		descripcion: "Ft.Lauderdale",
	},
	{
		code: "FPO",
		descripcion: "G.Bahama",
	},
	{
		code: "BIM",
		descripcion: "Bimini",
	},
];

export const BAG_FREE_FLL = "CBEBE";

export const BAG_FREE_FPO = "ENEVE";
